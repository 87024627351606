<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Phosphoric acid is used widely in fertilizers and is used in certain types of pop to make
        them more tart. It is a triprotic acid with a chemical formula
        <chemical-latex content="H3PO4(aq)\text{.}" />
        Estimate the
        <stemble-latex content="$\text{pH}$" />
        and the concentrations of all species at equilibrium in a
        <latex-number :number="concentration.toFixed(3)" unit="\text{M}" />
        solution of phosphoric acid. The three acid dissociation steps are as follows:
      </p>

      <v-simple-table class="mb-4 ml-4" style="white-space: nowrap">
        <tr style="height: 40px">
          <td style="vertical-align: top" class="pr-2">
            <chemical-latex content="H3PO4(aq) + H2O(l) <=> H2PO4^-(aq) + H3O^+(aq)" />
          </td>
          <td style="vertical-align: top">
            <stemble-latex content="$\text{K}_{\text{a}_\text{1}}=6.92\times 10^{-3}$" />
          </td>
        </tr>
        <tr style="height: 40px">
          <td style="vertical-align: top" class="pr-2">
            <chemical-latex content="H2PO4^-(aq) + H2O(l) <=> HPO4^{2-}(aq) + H3O^+(aq)" />
          </td>
          <td style="vertical-align: top">
            <stemble-latex content="$\text{K}_{\text{a}_\text{2}}=6.17\times 10^{-8}$" />
          </td>
        </tr>
        <tr style="height: 40px">
          <td style="vertical-align: top" class="pr-2">
            <chemical-latex content="HPO4^{2-}(aq) + H2O(l) <=> PO4^{3-}(aq) + H3O^+(aq)" />
          </td>
          <td style="vertical-align: top">
            <stemble-latex content="$\text{K}_{\text{a}_\text{3}}=4.79\times 10^{-13}$" />
          </td>
        </tr>
      </v-simple-table>

      <calculation-input
        v-model="inputs.h3po4"
        prepend-text="$\ce{a) [H3PO4]:}$"
        append-text="$\text{M}$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.h2po4"
        prepend-text="$\ce{b) [H2PO4-]:}$"
        append-text="$\text{M}$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.hpo4"
        prepend-text="$\ce{c) [HPO4^2-]:}$"
        append-text="$\text{M}$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.po4"
        prepend-text="$\ce{d) [PO4^3-]:}$"
        append-text="$\text{M}$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.h3o"
        prepend-text="$\ce{e) [H3O+]:}$"
        append-text="$\text{M}$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{f) pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'Question145',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        h3po4: null,
        h2po4: null,
        hpo4: null,
        po4: null,
        h3o: null,
        pH: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content.toFloat();
    },
  },
};
</script>
